<style>
.ant-form-extra {
  color: #F56C6C;
}
</style>
<template>
  <div style="background-color: #EBEEF5">
    <a-form-model
        ref="claimInfo"
        :selfUpdate="true"
        :rules="rules"
        :model="claimInfo">

      <a-card class="card" style="text-align: left" :title="policyTitleMap.policyTitle" :bordered="false">
        <a-form-model-item :label="policyTitleMap.policyNoTitle">
          {{ policyInfo.policyNo }}
        </a-form-model-item>
        <a-form-model-item :label="policyTitleMap.insuredNameTitle">
          {{ policyInfo.insuredName }}
        </a-form-model-item>
        <a-form-model-item :label="policyTitleMap.insuredCardNoTitle">
          {{ policyInfo.insuredCardNo }}
        </a-form-model-item>
        <a-form-model-item :label="policyTitleMap.policyTimeTitle">
          {{ policyInfo.safeguardTime }}
        </a-form-model-item>

        <a-form-model-item label="身份证有效期"  v-if="showCardExpiryDate">
          <a-date-picker
              v-model="claimInfo.driveReportVo.cardExpiryStartDate"
              input-read-only
              format="YYYY-MM-DD"
              :disabled-date="disabledDate"
              @change="selectCardExpiryStartDate"
              placeholder="请选择开始时间"/>

          <a-date-picker
              v-model="claimInfo.driveReportVo.cardExpiryEndDate"
              input-read-only
              format="YYYY-MM-DD"
              @change="selectCardExpiryEndDate"
              :open="cardExpiryEndDateShow"
              @focus="cardExpiryEndDateClick"
              placeholder="请选择结束时间">
            <template slot="renderExtraFooter">
              <a-button type="primary" @click="cardExpiryDateLongClick">
                长期
              </a-button>
            </template>
          </a-date-picker>
        </a-form-model-item>

      </a-card>

      <a-card class="card" style="text-align: left" title="报案信息" :bordered="false">

        <a-form-model-item label="报案人姓名" prop="driveReportVo.reportName">
          <a-input
              allow-clear
              v-model="claimInfo.driveReportVo.reportName"
              placeholder="请输入报案人姓名"
              autocomplete="off"/>
        </a-form-model-item>

        <a-form-model-item label="报案人手机号" prop="driveReportVo.reportPhoneNo"
                           extra="请核实手机号是否为学员本人或者负责人电话，便于理赔进度短信提醒。">
          <a-input
              allow-clear
              v-model="claimInfo.driveReportVo.reportPhoneNo"
              placeholder="请输入报案人手机号"
              autocomplete="off"/>
        </a-form-model-item>

        <a-form-model-item label="理赔类型" prop="driveReportVo.claimDriveType">
          <a-select
              allow-clear
              v-model="claimInfo.driveReportVo.claimDriveType"
              style="width: 100%"
              @change="selectClaimDriveType"
              placeholder="请选择理赔类型">
            <a-select-option v-for="claimDriveType in claimDriveTypeColumns" :key="claimDriveType.value">
              {{ claimDriveType.text }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
            label="考试科目"
            prop="driveReportVo.subject">
          <a-select
              allow-clear
              v-model="claimInfo.driveReportVo.subject"
              style="width: 100%"
              @change="selectClaimSubject"
              placeholder="请选择考试科目">
            <a-select-option v-for="subject in claimSubjectColumns" :key="subject.value">
              {{ subject.text }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="考试成绩" v-if="showExamScore">
          <a-input
              allow-clear
              v-model="claimInfo.driveReportVo.examScore"
              placeholder="请输入考试成绩"
              autocomplete="off"/>
        </a-form-model-item>

        <a-form-model-item
            label="考试地址"
            prop="driveReportVo.examProvinceCode">
          <a-select
              allow-clear
              v-model="claimInfo.driveReportVo.examProvinceCode"
              @change="selectExamProvince"
              placeholder="请选择省份">
            <a-select-option v-for="province in examProvinceList" :key="province.value">
              {{ province.text }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
            prop="driveReportVo.examCityCode">
          <a-select
              allow-clear
              v-model="claimInfo.driveReportVo.examCityCode"
              @change="selectExamCity"
              placeholder="请选择城市">
            <a-select-option v-for="city in examCityList" :key="city.value">
              {{ city.text }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
            prop="driveReportVo.examAreaCode"
            v-if="isNeedExamArea">
          <a-select
              allow-clear
              v-model="claimInfo.driveReportVo.examAreaCode"
              @change="selectExamArea"
              placeholder="请选择县区">
            <a-select-option v-for="city in examAreaList" :key="city.value">
              {{ city.text }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
            label="详细地址"
            prop="driveReportVo.examAddress">
          <a-input
              allow-clear
              v-model="claimInfo.driveReportVo.examAddress"
              placeholder="请输入详细地址" autocomplete="off"/>
        </a-form-model-item>

        <a-form-model-item
            label="考试时间"
            prop="driveReportVo.examTime">
          <a-date-picker
              v-model="claimInfo.driveReportVo.examTime"
              input-read-only
              format="YYYY-MM-DD"
              :disabled-date="disabledDate"
              @change="selectExamTime"
              placeholder="请选择考试时间"/>
        </a-form-model-item>

        <a-form-model-item
            label="考试费用"
            prop="driveReportVo.resit">
          <a-input
              allow-clear
              :disabled="claimResitIsReadonly"
              v-model="claimInfo.driveReportVo.resit"
              placeholder="请输入考试费用" autocomplete="off"/>
        </a-form-model-item>

        <a-form-model-item
            label="12123登录密码"
            v-if="isShowGovCnPswd"
            prop="driveReportVo.govCnPswd">
          <a-input
              allow-clear
              v-model="claimInfo.driveReportVo.govCnPswd"
              placeholder="请输入12123登录密码" autocomplete="off"/>
        </a-form-model-item>
      </a-card>

      <a-card id="bankInfo" class="card" style="text-align: left" title="账号信息" :bordered="false">
        <a-form-model-item
            label="账号类型"
            prop="bankAccountVo.cardType"
            extra="若赔付本人的话请提供本人办理的正确账户信息，以免耽误理赔时效。"
        >

          <a-select
              allow-clear
              v-model="claimInfo.bankAccountVo.cardType"
              style="width: 100%"
              @change="selectAccountType"
              placeholder="请选择账号类型">
            <a-select-option v-for="accountType in accountTypeColumns" :key="accountType.value">
              {{ accountType.text }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
            label="持卡人姓名"
            prop="bankAccountVo.accountName">

          <a-input
              allow-clear
              :disabled="accountNameIsReadonly"
              v-model="claimInfo.bankAccountVo.accountName"
              placeholder="请输入持卡人姓名" autocomplete="off"/>
        </a-form-model-item>


        <a-form-model-item
            label="持卡人证件号"
            prop="bankAccountVo.accountCardNo">
          <a-input
              allow-clear
              :disabled="accountCardNoIsReadonly"
              v-model="claimInfo.bankAccountVo.accountCardNo"
              placeholder="请输入持卡人证件号" autocomplete="off"/>
        </a-form-model-item>

        <a-form-model-item
            label="账号号码"
            prop="bankAccountVo.accountNo"
            :extra="accountNoTips"
        >
          <a-input
              allow-clear
              v-model="claimInfo.bankAccountVo.accountNo"
              placeholder="请输入银行卡号或第三方账号" autocomplete="off"/>
        </a-form-model-item>


        <a-form-model-item
            label="开户银行"
            prop="bankAccountVo.bankCode"
            extra="请确保开户银行的准确性，如填写错误会导致打款失败。"
        >
          <a-select
              allow-clear
              show-search
              show-arrow
              v-model="claimInfo.bankAccountVo.bankCode"
              style="width: 100%"
              @change="selectBank"
              @search="searchBankValue"
              :filter-option="filterOptionBank"
              placeholder="请选择或输入开户银行"
          >
            <a-select-option v-for="bank in bankColumns" :key="bank.value">
              {{ bank.text }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
            v-if="showBankAddress"
            label="开户地址"
            prop="bankAccountVo.bankProvinceCode">
          <a-select
              allow-clear
              v-model="claimInfo.bankAccountVo.bankProvinceCode"
              @change="selectBankProvince"
              placeholder="请选择省份">
            <a-select-option v-for="province in bankProvinceList" :key="province.value">
              {{ province.text }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
            v-if="showBankAddress"
            prop="bankAccountVo.bankCityCode">
          <a-select
              allow-clear
              v-model="claimInfo.bankAccountVo.bankCityCode"
              @change="selectBankCity"
              placeholder="请选择城市">

            <a-select-option v-for="city in bankCityList" :key="city.value">
              {{ city.text }}
            </a-select-option>

          </a-select>
        </a-form-model-item>

        <a-form-model-item
            v-if="showSubBank"
            label="开户支行"
            prop="bankAccountVo.subBankCode"
            extra="请确保支行信息正确，以免耽误理赔时效，支行信息请打银行客服电话确认。系统没有的支行可以选择上级分行。"
        >
          <a-select
              allow-clear
              style="width: 100%"
              v-model="claimInfo.bankAccountVo.subBankCode"
              show-search
              @change="selectSubBank"
              @search="searchSubBankValue"
              :filter-option="filterOptionBank"
              placeholder="请选择或输入开户支行">

            <a-select-option v-for="subBank in subBankColumns" :key="subBank.value">
              {{ subBank.text }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

      </a-card>

      <a-card class="card" style="text-align: left" title="理赔材料" :bordered="false">
        <div v-for="material in materialTypeList" :key="material.id">
          <a-form-model-item v-if="material.isShow" :key="material.id">
            <van-cell >
              <span v-html="material.remark"></span>
            </van-cell>
            <van-field name="uploader" label="">
              <template #input>
                <van-uploader v-model="material.fileList" :after-read="uploaderAfterRead" :deletable="material.deletable" @delete="deleteImg"
                              :max-count="material.maxCount"/>
              </template>
            </van-field>
          </a-form-model-item>
        </div>

        <a-form-model-item
            label="签名"
            v-if="isNeedSign">
          <a-row type="flex" style="margin-bottom: 10px">
            <a-col :span="20" style="color: #eb6f6f; font-weight: bolder">请工整、规范签名，若字迹潦草则无法理赔</a-col>
            <a-col :span="4">
              <a-button type="primary" @click="handleReset">清空</a-button>
            </a-col>
          </a-row>
          <a-row type="flex" justify="center">
            <vue-esign style="border-bottom-color: #2c3e50; border-width: 4px; border-style: solid;" ref="esign" :isClearBgColor="false" :height="400" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" />
          </a-row>
<!--          <button @click="handleGenerate">生成图片</button>-->
        </a-form-model-item>

        <a-form-model-item>
          <a-radio style="width: 100%; margin-bottom: 20px; text-overflow: ellipsis;white-space: normal;line-height: 18px;vertical-align: middle;display: inline-block;" :checked="isReadClaimProtocol" @click="changeReadClaimProtocol">
            我已阅读并同意《协助理赔申请服务协议》
            <a v-if="2===examAutoScreenshotType"
               target="_blank"
               style="overflow-wrap: anywhere;"
               href="https://static.jisubx.com/20230605/%E4%B8%AA%E4%BA%BA%E4%BF%A1%E6%81%AF%E4%BD%BF%E7%94%A8%E6%8E%88%E6%9D%83%E4%B9%A6.doc">
              《个人信息使用授权书》
            </a>
          </a-radio>
          <a-button html-type="button" style="width: 46%; margin-right: 4%" @click="saveDraft">保存草稿</a-button>
          <a-button html-type="submit" style="width: 46%; margin-left: 4%" type="primary" @click="applyClaim">申请理赔
          </a-button>
        </a-form-model-item>
      </a-card>

    </a-form-model>

    <van-overlay :show="showLoading">
      <div class="wrapper">
        <van-loading size="40px" vertical>请求中...</van-loading>
      </div>
    </van-overlay>

    <van-dialog v-model="showBackInfoTips"
                class="showBackInfoDialog"
                :width="showBackInfoTipsWidth"
                title="请确认以下信息是否正确"
                cancel-button-text="查看"
                show-cancel-button
                @cancel="cancelBackInfoTips"
                @confirm="claimBackInfoTips">
      <div style="text-align: left; margin-left: 40px; margin-top: 20px">
        <p>
            <span style="font-weight: bold; margin-right: 10px;">
                收款人:
            </span>
          {{ claimUpInfo.bankAccountVo.accountName }}
        </p>

        <p>
            <span style="font-weight: bold; margin-right: 10px;">
                收款账号:
            </span>
          {{ claimUpInfo.bankAccountVo.accountNo }}
        </p>
        <p v-if="claimUpInfo.bankAccountVo.bankName">
            <span style="font-weight: bold; margin-right: 10px;">
                开户行信息:
            </span>
          {{ claimUpInfo.bankAccountVo.bankName }}
        </p>
        <p v-if="claimUpInfo.bankAccountVo.subBankName">
            <span style="font-weight: bold; margin-right: 10px;">
                支行信息:
            </span>
          {{ claimUpInfo.bankAccountVo.subBankName }}
        </p>
        <p>
            <span style="font-weight: bold;color:coral;">
                银行卡必须是本人办理否则无法打款
            </span>
        </p>
      </div>
    </van-dialog>


  </div>
</template>

<script>

import {
  addClaimDrive,
  getAllNeedClaimInfo,
  getAreaInfoList,
  getCmpBankAreaInfoList,
  getBankInfoList,
  getCmpBankInfoList,
  getDriveClaimAmount,
  getSubBankList, getTiananBankQuery, getTiananRegionQuery,
  uploadImage, getProductAreaInfoList
} from "@/api/claim";
import {Dialog, Notify} from "vant";
import moment from 'moment';
import Compressor from 'compressorjs';
// 局部
import vueEsign from 'vue-esign'

export default {
  name: 'BaseForm',
  components: { vueEsign },
  data() {
    return {
      successCode:200,
      sourceType:0,
      labelCol: {lg: {span: 7}, sm: {span: 7}},
      wrapperCol: {lg: {span: 10}, sm: {span: 17}},
      showLoading: false,
      cardExpiryEndDateShow: false,
      showCardExpiryDate:false,
      showExamScore: false,
      showBankAddress:true,
      showSubBank:true,
      claimDriveTypeColumns: [
        {text: "单科不过", value: 1},
        {text: "重学", value: 2}
      ],
      claimSubjectColumns: [
        {text: "科目一", value: 1},
        {text: "科目二", value: 2},
        {text: "科目三", value: 3},
        {text: "科目四", value: 4}
      ],
      bankColumns: [],
      bankOtherCode: '99999',
      bankColumnsCount: 0,
      subBankColumns: [],
      subBankColumnsCount: 0,
      accountTypeColumns: [
        {text: '本人', value: 1},
        {text: '公司', value: 2},
        {text: '业务人员', value: 3}
      ],
      claimTypeMap: {
        1: "本人",
        2: "公司",
        3: "业务人员"
      },
      isReadClaimProtocol: false,
      accountNameIsReadonly:false,
      accountCardNoIsReadonly:false,
      claimResitIsReadonly:false,
      policyInfo: {
        policyNo: undefined,
        insuredCardNo: undefined,
        insuredName: undefined,
        safeguardTime: undefined,
        claimType: undefined,
        companyCode: undefined,
        insuredPhoneNo: undefined,
        signCode: undefined,
        insuredAge: undefined,
        productCode: undefined,
      },
      tianAnCmpCode: '1031',
      claimInfo: {
        claimCode: undefined,
        policyNo: undefined,
        insuredCardNo: undefined,
        claimType: undefined,
        claimTypeName: undefined,
        claimStatus: undefined,
        claimStatusName: undefined,
        remark: undefined,
        cmpClaimId: undefined,
        driveReportVo: {
          reportName: undefined,
          reportPhoneNo: undefined,
          claimDriveType: undefined,
          claimDriveTypeName: undefined,
          subject: undefined,
          subjectName: undefined,
          examProvinceCode: undefined,
          examProvinceName: undefined,
          examCityCode: undefined,
          examCityName: undefined,
          examAreaCode: undefined,
          examAreaName: undefined,
          examAddress: undefined,
          examTime: null,
          examTimeStr: null,
          resit: undefined,
          govCnPswd: undefined,
          signCode: undefined,
          cardExpiryStartDate: null,
          cardExpiryEndDate: null,
          examScore: null
        },
        bankAccountVo: {
          accountName: undefined,
          accountCardNo: undefined,
          accountNo: undefined,
          cardType: undefined,
          cardTypeName: undefined,
          bankCode: undefined,
          bankName: undefined,
          bankProvinceCode: undefined,
          bankProvinceName: undefined,
          bankCityCode: undefined,
          bankCityName: undefined,
          subBankName: undefined,
          subBankCode: undefined
        },
        materialVoList: []
      },
      beneficiaryInfoList: [],
      beneficiaryInfoMap: new Map,
      materialTypeList: [],
      materialTypeTempList: [],


      examProvinceList: [],
      examProvinceCityMap: new Map,
      examCityList: [],
      examCityAreaMap: new Map,
      examAreaList: [],
      isNeedExamArea: false,

      bankProvinceList: [],
      bankProvinceCityMap: new Map,
      bankCityList: [],

      claimInfoTypes:[],
      productClaimMap: {},
      isShowGovCnPswd: false,
      examAutoScreenshotType: 0,
      showBackInfoTips: false,
      showBackInfoTipsWidth: '320px',
      claimUpInfo: {
        bankAccountVo: {
          accountName: undefined,
          accountCardNo: undefined,
          accountNo: undefined,
          cardType: undefined,
          cardTypeName: undefined,
          bankCode: undefined,
          bankName: undefined,
          bankProvinceCode: undefined,
          bankProvinceName: undefined,
          bankCityCode: undefined,
          bankCityName: undefined,
          subBankName: undefined,
          subBankCode: undefined
        },
      },
      rules: {
        driveReportVo: {
          reportName: [
            {required: true, message: '请输入报案人姓名', trigger: 'blur'},
          ],
          reportPhoneNo: [
            {required: true, message: '请输入报案人手机号', trigger: 'blur'},
          ],
          claimDriveType: [
            {required: true, message: '请选择理赔类型', trigger: 'change'},
          ],
          subject: [
            {required: true, message: '请选择理赔科目', trigger: 'change'},
          ],
          examProvinceCode: [
            {required: true, message: '请选择考试省份', trigger: 'change'},
          ],
          examCityCode: [
            {required: true, message: '请选择考试城市', trigger: 'change'},
          ],
          examAreaCode: [
            {required: true, message: '请选择考试县区', trigger: 'change'},
          ],
          examAddress: [
            {required: true, message: '请输入考试详细地址', trigger: 'blur'},
          ],
          examTime: [
            {required: true, message: '请选择考试时间', trigger: 'change'},
          ],
          resit: [
            {required: true, message: '请输入考试费用', trigger: 'blur'},
          ],
          govCnPswd: [
            {required: true, message: '请输入12123登录密码', trigger: 'blur'},
          ]
        },
        bankAccountVo: {
          accountName: [
            {required: true, message: '请输入持卡人姓名', trigger: 'blur'},
          ],
          accountCardNo: [
            {required: true, message: '请输入持卡人证件号', trigger: 'blur'},
          ],
          accountNo: [
            {required: true, message: '请输入银行卡号或者第三方账号', trigger: 'blur'},
          ],
          cardType: [
            {required: true, message: '请选择账号类型', trigger: 'change'},
          ],
          bankCode: [
            {required: true, message: '请选择或输入开户银行', trigger: 'change'},
          ],
          bankProvinceCode: [
            {required: true, message: '请选择开户省份', trigger: 'change'},
          ],
          bankCityCode: [
            {required: true, message: '请选择开户城市', trigger: 'change'},
          ],
          subBankCode: [
            {required: true, message: '请选择或输入开户支行', trigger: 'change'},
          ]
        }
      },
      isNeedSign: false,
      lineWidth: 6,
      lineColor: '#000000',
      bgColor: '',
      resultImg: '',
      isCrop: false,
      accountNoTips: '请确保账号号码（银行卡号或第三方账号）输入正确，以免耽误理赔时效，注意不用添加空格。',
      policyTitleMap: {
        policyTitle: "",
        policyNoTitle: "",
        insuredNameTitle: "",
        insuredCardNoTitle: "",
        policyTimeTitle: ""
      }
    };
  },

  mounted() {
    this.loadClaimInfo();
    if (window.screen.width > 450 ) {
      this.showBackInfoTipsWidth = '450px';
    }
  },

  methods: {
    async loadClaimInfo() {

      this.claimInfo.policyNo = this.$route.query.policyNo;
      this.claimInfo.insuredCardNo = this.$route.query.insuredCardNo;
      this.claimInfo.claimCode = this.$route.query.claimCode;
      if (this.$route.query.sourceType) {
        this.sourceType = this.$route.query.sourceType;
      }
      this.showLoading = true;
      getAllNeedClaimInfo({
        insuredCardNo: this.claimInfo.insuredCardNo,
        policyNo: this.claimInfo.policyNo,
        claimCode: this.claimInfo.claimCode
      }).then(res => {
        this.showLoading = false;
        if (this.successCode === res.code) {
          //保单信息
          this.policyInfo = res.data.policyInfo;
          this.claimInfo.claimType = this.policyInfo.claimType;
          this.policyInfo.safeguardTime = this.policyInfo.startTime + " ~ " + this.policyInfo.endTime;
          //理赔信息
          if (res.data.claimInfo) {

            if (res.data.claimInfo.driveReportVo &&
                res.data.claimInfo.driveReportVo.examTime) {
              res.data.claimInfo.driveReportVo.examTime = moment(res.data.claimInfo.driveReportVo.examTime, 'YYYY-MM-DD')
            }

            if (res.data.claimInfo.driveReportVo &&
                res.data.claimInfo.driveReportVo.remark) {
              var remark = JSON.parse(res.data.claimInfo.driveReportVo.remark);
              res.data.claimInfo.driveReportVo.cardExpiryStartDate = moment(remark.cardExpiryStartDate, 'YYYY-MM-DD')
              res.data.claimInfo.driveReportVo.cardExpiryEndDate = moment(remark.cardExpiryEndDate, 'YYYY-MM-DD')
            }

            this.claimInfo = res.data.claimInfo;

            if (res.data.claimInfo.driveReportVo) {
              if (1 === res.data.claimInfo.driveReportVo.claimDriveType) {
                this.claimResitIsReadonly = true;
              }else {
                this.claimResitIsReadonly = false;
              }
            }

            if (this.claimInfo.bankAccountVo) {
              if (undefined === this.claimInfo.bankAccountVo.subBankCode || this.claimInfo.bankAccountVo.subBankCode.length < 1) {
                this.claimInfo.bankAccountVo.subBankCode = this.claimInfo.bankAccountVo.subBankName;
              }

              if (this.claimInfo.bankAccountVo.cardType && this.claimInfo.bankAccountVo.cardType === 1) {
                this.accountNameIsReadonly = true;
                this.accountCardNoIsReadonly = true;
                this.claimInfo.bankAccountVo.accountName = this.policyInfo.insuredName;
                this.claimInfo.bankAccountVo.accountCardNo = this.policyInfo.insuredCardNo;
              }
            }

          } else {
            this.claimInfo.driveReportVo.reportName = this.policyInfo.insuredName;
            this.claimInfo.driveReportVo.reportPhoneNo = this.policyInfo.insuredPhoneNo;
            this.claimInfo.driveReportVo.signCode = this.policyInfo.signCode;

            this.claimInfo.bankAccountVo.cardType = 1;
            this.accountNameIsReadonly = true;
            this.accountCardNoIsReadonly = true;

            this.claimInfo.bankAccountVo.accountName = this.policyInfo.insuredName;
            this.claimInfo.bankAccountVo.accountCardNo = this.policyInfo.insuredCardNo;
          }

          //自动考试截图类型 （1-考试截图）
          this.examAutoScreenshotType = res.data.examAutoScreenshotType;

          //账号提示处理
          if (this.claimInfo.companyCode === '1040') {
            this.accountNoTips = this.accountNoTips + '若选择支付宝  请在这里填写支付宝账号。';
          }

          //理赔材料信息
          if (res.data.materialTypeList) {
            for (var idx in res.data.materialTypeList) {
              var materialType = res.data.materialTypeList[idx];
              if (materialType.materialType == 7) {
                this.isNeedSign = true;
                continue;
              }

              //特殊处理
              if (materialType.materialType == 10
                  && (this.claimInfo.companyCode == '1042'
                      || this.claimInfo.companyCode == '1045')
                  && res.data.channel
                  && res.data.channel.linkman == '潘总') {
                console.log("特殊处理理赔材料前端不展示");
                continue;
              }

              if (!materialType.isFrontShow) {
                console.log("理赔材料前端不展示");
                continue;
              }
              materialType.isShow = true;
              //自动截图不展示
              if (materialType.relType
                  && 2 === materialType.relType
                  && 2 === this.examAutoScreenshotType) {
                console.log("需要自动截图不展示");
                // continue;
                materialType.isShow = false;
              }

              var fileList = [];

              materialType.deletable = true;
              var firstExamClaimMaterial = res.data.firstExamClaimMaterial;
              if (materialType.materialType == 8
                  && firstExamClaimMaterial
                  && firstExamClaimMaterial.materialUrl) {
                fileList.push({
                  uid: 0,
                  url: firstExamClaimMaterial.materialUrl,
                  name: 'image.png',
                  status: 'done',
                  materialType: materialType.materialType
                });
                materialType.deletable = false;
                materialType.fileList = fileList;
                materialType.fileList = fileList;
                this.materialTypeList.push(materialType);
                this.materialTypeTempList.push(this.deepClone(materialType));
                continue;
              }

              if (undefined !== this.claimInfo
                  && undefined !== this.claimInfo.materialVoList
                  && this.claimInfo.materialVoList.length > 0) {
                for (var index in this.claimInfo.materialVoList) {
                  var material = this.claimInfo.materialVoList[index];
                  if (materialType.materialType == material.materialType) {
                    fileList.push({
                      uid: index,
                      url: material.materialUrl,
                      name: 'image.png',
                      status: 'done',
                      materialType: material.materialType
                    });
                  }
                }
              }
              materialType.fileList = fileList;
              this.materialTypeList.push(materialType);
              this.materialTypeTempList.push(this.deepClone(materialType));
            }

          }

          //受益人信息
          if (res.data.beneficiaryInfoList && res.data.beneficiaryInfoList.length > 0) {
            this.beneficiaryInfoList = res.data.beneficiaryInfoList;
            for (var i in this.beneficiaryInfoList) {
              var beneficiaryInfo = this.beneficiaryInfoList[i];
              this.accountTypeColumns.push({
                text: beneficiaryInfo.bankAccountName,
                value: beneficiaryInfo.id
              });

              this.beneficiaryInfoMap.set(beneficiaryInfo.id, beneficiaryInfo);
            }
          }

          //理赔信息类型
          if (res.data.claimInfoTypes && res.data.claimInfoTypes.length > 0) {
            this.claimInfoTypes = res.data.claimInfoTypes;
          }

          if (res.data.productClaimMap) {
            this.productClaimMap = res.data.productClaimMap;
          }

          if (this.claimInfoTypes.includes(2)) {
            this.showCardExpiryDate = true;
          }

          if (this.claimInfoTypes.includes(8)) {
            this.showExamScore = true;
          }

          if (this.claimInfoTypes.includes(11)) {
            this.showBankAddress = false
            this.showSubBank = false
          }

          if (this.claimInfoTypes.includes(17)) {
            this.isShowGovCnPswd = true
          }

          if (this.claimInfoTypes.includes(19)) {
            this.isNeedExamArea = true;
          }

          if (this.claimInfoTypes.includes(20)) {
            this.policyTitleMap = {
              policyTitle: "信息",
              policyNoTitle: "单号",
              insuredNameTitle: "学员",
              insuredCardNoTitle: "证件号",
              policyTimeTitle: "保障期限"
            };
          } else {
            this.policyTitleMap = {
              policyTitle: "保单信息",
              policyNoTitle: "保单号",
              insuredNameTitle: "被保人",
              insuredCardNoTitle: "被保人证件号",
              policyTimeTitle: "保障期限"
            };
          }

          //处理理赔类型
          this.dealWithClaimDriveType();
          //处理理赔科目
          this.dealWithClaimSubject();

          //华平众安支付宝理赔账号
          if (this.claimInfo.bankAccountVo.bankCode === 'ALIPAY') {
            this.showBankAddress = false
            this.showSubBank = false
          }

          if (this.claimInfoTypes.includes(15)) {
            var productClaim =  this.productClaimMap[15];
            if (productClaim && productClaim.remark) {
              var remark1 = JSON.parse(productClaim.remark);
              if (remark1 && remark1.includes(this.claimInfo.bankAccountVo.bankCode)) {
                this.showBankAddress = false
                this.showSubBank = false
              }
            }
          }

          this.getSystemAreaInfo();

          if (this.claimInfoTypes.includes(5)) {
            this.getCompanyBankAreaInfo();
          }

          /// 处理天安银行
          if (this.tianAnCmpCode === this.policyInfo.companyCode) {
            this.getTianAnBank();
            this.getTianAnProvince();
            if (undefined !== this.claimInfo.bankAccountVo) {
              this.getTianAnCity(this.claimInfo.bankAccountVo.bankProvinceCode)
            }
            this.getTianAnSubBank();

          } else {

            if (undefined !== this.claimInfo.bankAccountVo
                && this.bankOtherCode === this.claimInfo.bankAccountVo.bankCode) {
              this.bankColumns.unshift({"text": this.claimInfo.bankAccountVo.bankName, "value": this.bankOtherCode});
            }

            if (this.claimInfoTypes.includes(1)) {
              this.getCompanyBankInfoList();
            } else {
              this.getSystemBankInfoList();
            }

            if (undefined !== this.claimInfo.bankAccountVo) {
              this.bankCityList = this.examProvinceCityMap.get(this.claimInfo.bankAccountVo.bankProvinceCode);
              this.loadSubBankList();
            }
          }

          //处理理赔金额
          this.getClaimAmount();
        } else {
          Notify({type: 'danger', message: res.message?res.message:"信息错误"});
        }
        this.loading = false;
      }).catch(res => {
        this.showLoading = false;
        console.log(res);
        Notify({type: 'danger', message: "接口请求失败！"});
      });

    },
    applyClaim() {
      this.$refs.claimInfo.validate(valid => {
        if (valid) {
          if (!this.isReadClaimProtocol) {
            Notify({type: 'warning', message: '请阅读并同意《协助理赔申请服务协议》'});
            return;
          }
          //材料校验
          var materialVoList_temp = []
          for (var idx in this.materialTypeList) {
            var m = this.materialTypeList[idx];
            if (m.fileList.length < m.minCount) {
              Notify({type: 'warning', message: m.materialTitle + '最少' + m.minCount + "张"});
              return;
            }
            for (var index in m.fileList) {
              var file = m.fileList[index];
              materialVoList_temp.push({
                materialType: file.materialType,
                materialUrl: file.url
              });
            }
          }
          this.claimInfo.materialVoList = materialVoList_temp;
          this.claimInfo.claimStatus = 2;
          //理赔来源
          this.claimInfo.claimSource = 1;


          if (this.bankOtherCode === this.claimInfo.bankAccountVo.bankCode) {
            this.claimInfo.bankAccountVo.bankName = this.bankColumns[0].text;
          }

          var upClaimInfo = this.deepClone(this.claimInfo);
          if (this.claimInfo.driveReportVo.examTime) {
            upClaimInfo.driveReportVo.examTime = moment(this.claimInfo.driveReportVo.examTime).format('YYYY-MM-DD')
          }

          if (this.claimInfo.driveReportVo.cardExpiryStartDate) {
            upClaimInfo.driveReportVo.cardExpiryStartDate = moment(this.claimInfo.driveReportVo.cardExpiryStartDate).format('YYYY-MM-DD')
          }

          if (this.claimInfo.driveReportVo.cardExpiryEndDate) {
            upClaimInfo.driveReportVo.cardExpiryEndDate = moment(this.claimInfo.driveReportVo.cardExpiryEndDate).format('YYYY-MM-DD')
          }

          if (this.showCardExpiryDate) {
            if (!upClaimInfo.driveReportVo.cardExpiryStartDate || !upClaimInfo.driveReportVo.cardExpiryEndDate){
              Notify({type: 'danger', message: '证件有效期不能为空！'});
              return false;
            }
          }

          if (this.showExamScore) {
            if (!upClaimInfo.driveReportVo.examScore){
              Notify({type: 'danger', message: '考试成绩不能为空！'});
              return false;
            }
          }

          if (this.isNeedSign) {
            this.$refs.esign.generate().then(res => {
              this.resultImg = res;
              console.log(""+ res);
              this.showLoading = true;
              uploadImage({file: this.resultImg}).then(upRes => {
                this.showLoading = false;
                if (this.successCode === upRes.code) {
                  upClaimInfo.materialVoList.push({
                    materialType: 7,
                    materialUrl: upRes.data
                  });
                  this.claimApplyRequest(upClaimInfo);
                } else {
                  Notify({type: 'danger', message: '签名上传失败！'});
                }
              }).catch(() => {
                this.showLoading = false;
                Notify({type: 'danger', message: '签名上传失败！'});
              });

            }).catch(err => {
              console.log(err);
              Notify({type: 'danger', message: '请输入签名！'});
              return false;
              // alert(err) // 画布没有签字时会执行这里 'Not Signned'
            })
          } else {
            this.claimApplyRequest(upClaimInfo);
          }
        } else {
          console.log('error submit!!');
          Notify({type: 'danger', message: '请检查信息是否提交完整'});
          return false;
        }
      });

      // this.$message.info('This is a normal message');
      // this.$notification.open({
      //   message: 'Notification Title',
      //   description:
      //       'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      //   onClick: () => {
      //     console.log('Notification Clicked!');
      //   },
      // });


      // if (!values.isReadClaimProtocol) {
      //   Notify({ type: 'warning', message: '请阅读并同意《协助理赔申请服务协议》' });
      //   return;
      // }


    },
    //理赔申请 请求
    claimApplyRequest(upClaimInfo) {
      // Dialog.alert({
      //   title: '提示',
      //   message: '请确认银行卡信息是否正确，是否本人？',
      //   confirmButtonText: '提交',
      //   showCancelButton: true,
      //   cancelButtonText:'查看'
      // }).then(() => {
      //   this.showLoading = true;
      //   addClaimDrive(upClaimInfo).then(res => {
      //     this.showLoading = false;
      //     if (this.successCode === res.code) {
      //       if (2 === this.examAutoScreenshotType) {
      //         Dialog.alert({
      //           title: '提示',
      //           message: '请授权进行考试截图',
      //         }).then(() => {
      //           // on close
      //           this.$router.push({path: '/claim/examQr', query: {claimCode: res.data.claimCode}});
      //         });
      //       } else  {
      //         Dialog.alert({
      //           title: '提示',
      //           message: '您的理赔申请已提交审核，理赔进度您可通过“我的理赔”功能进行查询',
      //         }).then(() => {
      //           // on close
      //           this.$router.push("/claim/policySelect");
      //         });
      //       }
      //     } else {
      //       Notify({type: 'warning', message: res.message});
      //     }
      //   }).catch(() => {
      //     this.showLoading = false;
      //     Notify({type: 'danger', message: "理赔申请失败！"});
      //   });
      //
      // }).catch(() => {
      //   // location.href = "#bankInfo";
      //   document.querySelector("#bankInfo").scrollIntoView(true);
      // });

      this.claimUpInfo = upClaimInfo;
      this.showBackInfoTips = true;
    },
    claimBackInfoTips() {
      this.showBackInfoTips = false;
      this.showLoading = true;
      addClaimDrive(this.claimUpInfo).then(res => {
        this.showLoading = false;
        if (this.successCode === res.code) {
          if (2 === this.examAutoScreenshotType) {
            Dialog.alert({
              title: '提示',
              message: '请授权进行考试截图',
            }).then(() => {
              // on close
              this.$router.push({path: '/claim/examQr', query: {claimCode: res.data.claimCode}});
            });
          } else  {
            Dialog.alert({
              title: '提示',
              message: '您的理赔申请已提交审核，理赔进度您可通过“我的理赔”功能进行查询',
            }).then(() => {
              // on close
              this.$router.push("/claim/policySelect");
            });
          }
        } else {
          Notify({type: 'warning', message: res.message});
        }
      }).catch(() => {
        this.showLoading = false;
        Notify({type: 'danger', message: "理赔申请失败！"});
      });
    },
    cancelBackInfoTips() {
      this.showBackInfoTips = false;
      document.querySelector("#bankInfo").scrollIntoView(true);
    },

    saveDraft() {
      if (!this.isReadClaimProtocol) {
        Notify({type: 'warning', message: '请阅读并同意《协助理赔申请服务协议》'});
        return;
      }
      //理赔材料处理
      var materialVoList_temp = []
      for (var idx in this.materialTypeList) {
        var m = this.materialTypeList[idx];
        for (var index in m.fileList) {
          var file = m.fileList[index];
          materialVoList_temp.push({
            materialType: file.materialType,
            materialUrl: file.url
          });
        }
      }

      this.claimInfo.materialVoList = materialVoList_temp;
      this.claimInfo.claimStatus = 1;
      //理赔来源
      this.claimInfo.claimSource = 1;

      var upClaimInfo = this.deepClone(this.claimInfo);
      if (this.claimInfo.driveReportVo.examTime) {
        upClaimInfo.driveReportVo.examTime = moment(this.claimInfo.driveReportVo.examTime).format('YYYY-MM-DD')
      }

      if (this.claimInfo.driveReportVo.cardExpiryStartDate) {
        upClaimInfo.driveReportVo.cardExpiryStartDate = moment(this.claimInfo.driveReportVo.cardExpiryStartDate).format('YYYY-MM-DD')
      }

      if (this.claimInfo.driveReportVo.cardExpiryEndDate) {
        upClaimInfo.driveReportVo.cardExpiryEndDate = moment(this.claimInfo.driveReportVo.cardExpiryEndDate).format('YYYY-MM-DD')
      }

      if (this.isNeedSign) {
        this.$refs.esign.generate().then(res => {
          this.resultImg = res;
          console.log(""+ res);
          uploadImage({file: this.resultImg}).then(upRes => {
            if (this.successCode === upRes.code) {
              upClaimInfo.materialVoList.push({
                materialType: 7,
                materialUrl: upRes.data
              });
              this.saveDraftRequest(upClaimInfo);
            } else {
              Notify({type: 'danger', message: '签名上传失败！'});
            }
          }).catch(() => {
            Notify({type: 'danger', message: '签名上传失败！'});
          });

        }).catch(err => {
          console.log(err);
          // alert(err) // 画布没有签字时会执行这里 'Not Signned'
        })
      } else {
        this.saveDraftRequest(upClaimInfo);
      }
    },

    //草稿理赔
    saveDraftRequest(upClaimInfo) {
      this.showLoading = true;
      addClaimDrive(upClaimInfo).then(res => {
        this.showLoading = false;
        if (this.successCode === res.code) {
          Dialog.alert({
            title: '提示',
            message: '信息已保存为草稿以便下次编辑。如需正式提交请点击“提交审核”按钮',
          }).then(() => {
          });
        } else {
          this.showLoading = false;
          Notify({type: 'warning', message: res.message});
        }
      }).catch(() => {
        this.showLoading = false;
        Notify({type: 'danger', message: "理赔申请失败"});
      });
    },

    async getSystemAreaInfo() {
      await getAreaInfoList()
          .then(res => {
            if (this.successCode === res.code) {
              this.examProvinceList = res.data;
              var bankProvinceCityMap_temp = new Map;
              for (var indx in this.examProvinceList) {
                var areaInfo = this.examProvinceList[indx];
                this.examProvinceCityMap.set(areaInfo.value, areaInfo.children);
                bankProvinceCityMap_temp.set(areaInfo.value, areaInfo.children);
                for (var idx in areaInfo.children) {
                  var cityAreaInfo = areaInfo.children[idx];
                  this.examCityAreaMap.set(cityAreaInfo.value, cityAreaInfo.children);
                }
              }
              if (undefined !== this.claimInfo.driveReportVo) {
                this.examCityList = this.examProvinceCityMap.get(this.claimInfo.driveReportVo.examProvinceCode);
                if (this.claimInfo.driveReportVo.remark) {
                  var remark = JSON.parse(this.claimInfo.driveReportVo.remark);
                  this.claimInfo.driveReportVo.examAreaCode = remark.examAreaCode;
                  this.claimInfo.driveReportVo.examAreaName = remark.examAreaName;
                  this.examAreaList = this.examCityAreaMap.get(this.claimInfo.driveReportVo.examCityCode);
                }
              }

              if (this.tianAnCmpCode !== this.policyInfo.companyCode && !this.claimInfoTypes.includes(5)) {
                this.bankProvinceList = JSON.parse(JSON.stringify(res.data));
                this.bankProvinceCityMap = bankProvinceCityMap_temp;
                if (undefined !== this.claimInfo.bankAccountVo) {
                  this.bankCityList = this.bankProvinceCityMap.get(this.claimInfo.bankAccountVo.bankProvinceCode);
                }
              }

              //处理特殊地址
              this.dealWithPlanExamAddress()

            }
          }).catch();
    },

    dealWithPlanExamAddress() {
      if (this.claimInfoTypes.includes(22)) {
        getProductAreaInfoList({productCode: this.policyInfo.productCode})
            .then(res => {
              if (this.successCode === res.code) {
                var remark = JSON.parse(res.data);
                var planArea = remark[this.policyInfo.planCode];
                if (planArea) {
                  this.examProvinceList = planArea;
                  for (var indx in this.examProvinceList) {
                    var areaInfo = this.examProvinceList[indx];
                    this.examProvinceCityMap.set(areaInfo.value, areaInfo.children);
                    for (var idx in areaInfo.children) {
                      var cityAreaInfo = areaInfo.children[idx];
                      this.examCityAreaMap.set(cityAreaInfo.value, cityAreaInfo.children);
                    }
                  }
                  if (undefined !== this.claimInfo.driveReportVo) {
                    this.examCityList = this.examProvinceCityMap.get(this.claimInfo.driveReportVo.examProvinceCode);
                    if (this.claimInfo.driveReportVo.remark) {
                      var examAreaRemark = JSON.parse(this.claimInfo.driveReportVo.remark);
                      this.claimInfo.driveReportVo.examAreaCode = examAreaRemark.examAreaCode;
                      this.claimInfo.driveReportVo.examAreaName = examAreaRemark.examAreaName;
                      this.examAreaList = this.examCityAreaMap.get(this.claimInfo.driveReportVo.examCityCode);
                    }
                  }
                }
              }
            }).catch();
        // if (this.productClaimMap) {
        //   var productClaim =  this.productClaimMap[22];
        //   if (productClaim && productClaim.remark) {
        //     var remark = JSON.parse(productClaim.remark);
        //     var planArea = remark[this.policyInfo.planCode];
        //     if (planArea) {
        //       this.examProvinceList = planArea;
        //       for (var indx in this.examProvinceList) {
        //         var areaInfo = this.examProvinceList[indx];
        //         this.examProvinceCityMap.set(areaInfo.value, areaInfo.children);
        //         for (var idx in areaInfo.children) {
        //           var cityAreaInfo = areaInfo.children[idx];
        //           this.examCityAreaMap.set(cityAreaInfo.value, cityAreaInfo.children);
        //         }
        //       }
        //       if (undefined !== this.claimInfo.driveReportVo) {
        //         this.examCityList = this.examProvinceCityMap.get(this.claimInfo.driveReportVo.examProvinceCode);
        //         if (this.claimInfo.driveReportVo.remark) {
        //           var examAreaRemark = JSON.parse(this.claimInfo.driveReportVo.remark);
        //           this.claimInfo.driveReportVo.examAreaCode = examAreaRemark.examAreaCode;
        //           this.claimInfo.driveReportVo.examAreaName = examAreaRemark.examAreaName;
        //           this.examAreaList = this.examCityAreaMap.get(this.claimInfo.driveReportVo.examCityCode);
        //         }
        //       }
        //     }
        //   }
        // }
      }
    },

    async getCompanyBankAreaInfo() {
      await getCmpBankAreaInfoList({companyCode:this.policyInfo.companyCode})
          .then(res => {
            if (this.successCode === res.code) {
              this.bankProvinceList = res.data;
              for (var indx in this.bankProvinceList) {
                var areaInfo = this.bankProvinceList[indx];
                this.bankProvinceCityMap.set(areaInfo.value, areaInfo.children);
              }
              if (undefined !== this.claimInfo.bankAccountVo) {
                this.bankCityList = this.bankProvinceCityMap.get(this.claimInfo.bankAccountVo.bankProvinceCode);
              }
            }
          }).catch();
    },

    getSystemBankInfoList() {
      getBankInfoList().then(res => {
        if (this.successCode === res.code) {
          var bankList = res.data;
          // var banks = [];
          for (var idx in bankList) {
            var bank = bankList[idx];
            this.bankColumns.push({
              "text": bank.bankName,
              "value": bank.bankCode
            });
          }
          // this.bankColumns = banks;
          // this.bankColumnsCount = this.bankColumns.length;
        }
      }).catch();
    },

    getCompanyBankInfoList() {
      getCmpBankInfoList({companyCode:this.policyInfo.companyCode}).then(res => {
        if (this.successCode === res.code) {
          var bankList = res.data;
          // var banks = [];
          for (var idx in bankList) {
            var bank = bankList[idx];
            this.bankColumns.push({
              "text": bank.bankName,
              "value": bank.bankCode
            });
          }
          // this.bankColumns = banks;
          // this.bankColumnsCount = this.bankColumns.length;
        }
      }).catch();
    },


    getTianAnProvince() {
      getTiananRegionQuery({}).then(res => {
        if (this.successCode === res.code) {
          var provinceList = res.data;
          var provinceArr = [];
          for (var idx in provinceList) {
            var province = provinceList[idx];
            provinceArr.push({
              "text": province.name,
              "value": province.code
            });
          }
          this.bankProvinceList = provinceArr;
        }
      }).catch();
    },
    getTianAnCity(provinceCode) {
      this.bankCityList = [];
      getTiananRegionQuery({provinceCode: provinceCode}).then(res => {
        if (this.successCode === res.code) {
          var cityList = res.data;
          var cityeArr = [];
          for (var idx in cityList) {
            var province = cityList[idx];
            cityeArr.push({
              "text": province.name.split("_")[1],
              "value": province.code,
            });
          }
          this.bankCityList = cityeArr;
        }
      }).catch();
    },
    getTianAnBank() {
      getTiananBankQuery({reqType: 1}).then(res => {
        if (this.successCode === res.code) {
          var bankList = res.data;
          var banks = [];
          for (var idx in bankList) {
            var bank = bankList[idx];
            banks.push({
              "text": bank.name,
              "value": bank.directBankCode
            });
          }
          this.bankColumns = banks;
        }
      }).catch();
    },
    getTianAnSubBank() {
      this.subBankColumns = [];
      var bankCode = this.claimInfo.bankAccountVo.bankCode;
      var provinceCode = this.claimInfo.bankAccountVo.bankProvinceCode;
      var cityCode = this.claimInfo.bankAccountVo.bankCityCode;
      getTiananBankQuery({
        reqType: 2,
        bankcode: bankCode,
        provinceCode: provinceCode,
        cityCode: cityCode,
        bankName: ""
      }).then(res => {
        if (this.successCode === res.code) {
          var bankList = res.data;
          var banks = [];
          for (var idx in bankList) {
            var bank = bankList[idx];
            banks.push({
              "text": bank.name,
              "value": bank.code
            });
          }
          if (this.subBankColumns.length >= 1) {
            this.subBankColumns.splice(0, 1);
          }
          this.subBankColumns = banks;
        }
      }).catch();
    },


    selectClaimDriveType(value) {
      console.log(value);
      if (1 === value) {
        this.claimResitIsReadonly = true;
      }else {
        this.claimResitIsReadonly = false;
      }
    },

    selectClaimSubject(value) {
      console.log(value);
      this.getClaimAmount();
    },
    selectExamProvince(value) {
      for (var idx in this.examProvinceList) {
        var areaInfo = this.examProvinceList[idx];
        if (value === areaInfo.value) {
          this.claimInfo.driveReportVo.examProvinceName = areaInfo.text;
        }
      }
      this.examCityList = this.examProvinceCityMap.get(value);
      this.examAreaList = [];
      this.claimInfo.driveReportVo.examCityCode = undefined;
      this.claimInfo.driveReportVo.examAreaCode = undefined;
      this.getClaimAmount();
    },
    selectExamCity(value) {
      for (var idx in this.examCityList) {
        var areaInfo = this.examCityList[idx];
        if (value === areaInfo.value) {
          this.claimInfo.driveReportVo.examCityName = areaInfo.text;
        }
      }
      this.examAreaList = this.examCityAreaMap.get(value);
      this.claimInfo.driveReportVo.examAreaCode = undefined;
      this.getClaimAmount();
    },
    selectExamArea(value) {
      for (var idx in this.examAreaList) {
        var areaInfo = this.examAreaList[idx];
        if (value === areaInfo.value) {
          this.claimInfo.driveReportVo.examAreaName = areaInfo.text;
        }
      }
    },
    selectExamTime(date, dateString) {
      console.log(date, dateString);
      this.claimInfo.driveReportVo.examTimeStr = dateString;
      this.getClaimAmount();
    },

    /**
     * 金额处理
     */
    examTimeAndClaimAmountDealWith(){
      //安徽地区金额调整2022-04-01 科目二240科目三240 调整为 科目二 180 科目三 140
      if ('340000' === this.claimInfo.driveReportVo.examProvinceCode){
        if (this.claimInfo.driveReportVo.examTime && this.claimInfo.driveReportVo.subject){
          var examTimeStr = moment(this.claimInfo.driveReportVo.examTime).format('YYYY-MM-DD');
          if (moment(examTimeStr).isBefore('2022-04-01')) {
            if (2 === this.claimInfo.driveReportVo.subject
                || 3 === this.claimInfo.driveReportVo.subject) {
              this.claimInfo.driveReportVo.resit = 240;
            }
          }
        }
      }

      //极速考试通的陕西科目三金额是128
      if ('610000' === this.claimInfo.driveReportVo.examProvinceCode
          &&  '1036' === this.policyInfo.companyCode
          && 3 === this.claimInfo.driveReportVo.subject){
        this.claimInfo.driveReportVo.resit = 128;
      }

      //理赔金额比率
      if (this.claimInfoTypes.includes(12)) {
        if (this.policyInfo.insuredAge
            && this.productClaimMap) {
          var productClaim =  this.productClaimMap[12];
          if (productClaim && productClaim.remark) {
            var remark = JSON.parse(productClaim.remark);
            if ("age" === remark.requirement) {

              var planAgeValue = remark[this.policyInfo.planCode];
              if (planAgeValue) {
                if (planAgeValue.geAge) {
                  if (this.policyInfo.insuredAge >= planAgeValue.geAge) {
                    this.claimInfo.driveReportVo.resit = this.claimInfo.driveReportVo.resit * planAgeValue.rate;
                  }
                }
                return;
              }

              if (remark.afterStartTime) {
                if (moment(this.policyInfo.startTime).isAfter(remark.afterStartTime)) {
                  if (remark.geAge) {
                    if (this.policyInfo.insuredAge >= remark.geAge) {
                      this.claimInfo.driveReportVo.resit = this.claimInfo.driveReportVo.resit * remark.rate;
                    }
                  }
                }
              } else {
                if (remark.geAge) {
                  if (this.policyInfo.insuredAge >= remark.geAge) {
                    this.claimInfo.driveReportVo.resit = this.claimInfo.driveReportVo.resit * remark.rate;
                  }
                }
              }
            }
          }
        }
      }

    },

    selectCardExpiryStartDate(date, dateString) {
      console.log(date, dateString);
      this.claimInfo.driveReportVo.cardExpiryStartDate = dateString;
    },

    selectCardExpiryEndDate(date, dateString) {
      console.log(date, dateString);
      this.cardExpiryEndDateShow = !this.cardExpiryEndDateShow;
      this.claimInfo.driveReportVo.cardExpiryEndDate = dateString;
    },

    cardExpiryEndDateClick() {
      this.cardExpiryEndDateShow = !this.cardExpiryEndDateShow;
    },

    cardExpiryDateLongClick() {
      this.cardExpiryEndDateShow = false;
      this.claimInfo.driveReportVo.cardExpiryEndDate = moment('9999-12-31', 'YYYY-MM-DD');
    },

    disabledDate(current) {
      return current >= moment().endOf('day');
    },

    getClaimAmount() {
      if (undefined !== this.claimInfo.driveReportVo.examProvinceCode
          && undefined !== this.claimInfo.driveReportVo.subject) {
        let examTime = null;
        if (this.claimInfo.driveReportVo.examTime) {
          examTime = moment(this.claimInfo.driveReportVo.examTime).format('YYYY-MM-DD')
        }
        getDriveClaimAmount({
          productCode: this.policyInfo.productCode,
          provinceCode: this.claimInfo.driveReportVo.examProvinceCode,
          cityCode: this.claimInfo.driveReportVo.examCityCode,
          subject: this.claimInfo.driveReportVo.subject,
          examTime: examTime
        }).then(res => {
          if (this.successCode === res.code) {
            // this.$set(this.claimInfo.driveReportVo, "resit", res.data.amount);
            this.claimInfo.driveReportVo.resit = res.data;
            //处理理赔金额调整
            this.examTimeAndClaimAmountDealWith();
          }
        }).catch();
      }
    },

    selectAccountType(value) {
      if (1 == value) {
        this.accountNameIsReadonly = true;
        this.accountCardNoIsReadonly = true;

        this.claimInfo.bankAccountVo.accountName = this.policyInfo.insuredName;
        this.claimInfo.bankAccountVo.accountCardNo = this.policyInfo.insuredCardNo;
        this.claimInfo.bankAccountVo.accountNo = undefined;
        this.claimInfo.bankAccountVo.bankCode = undefined;
        this.claimInfo.bankAccountVo.bankName = undefined;

        this.bankProvinceAndCityValue = undefined;
        this.claimInfo.bankAccountVo.bankProvinceCode = undefined;
        this.claimInfo.bankAccountVo.bankProvinceName = undefined;
        this.claimInfo.bankAccountVo.bankCityCode = undefined;
        this.claimInfo.bankAccountVo.bankCityName = undefined;
        this.claimInfo.bankAccountVo.subBankCode = undefined;
        this.claimInfo.bankAccountVo.subBankName = undefined;

      } else if (2 == value || 3 == value) {
        this.accountNameIsReadonly = false;
        this.accountCardNoIsReadonly = false;

        this.claimInfo.bankAccountVo.accountName = undefined;
        this.claimInfo.bankAccountVo.accountCardNo = undefined;
        this.claimInfo.bankAccountVo.accountNo = undefined;
        this.claimInfo.bankAccountVo.bankCode = undefined;
        this.claimInfo.bankAccountVo.bankName = undefined;

        this.bankProvinceAndCityValue = undefined;
        this.claimInfo.bankAccountVo.bankProvinceCode = undefined;
        this.claimInfo.bankAccountVo.bankProvinceName = undefined;
        this.claimInfo.bankAccountVo.bankCityCode = undefined;
        this.claimInfo.bankAccountVo.bankCityName = undefined;
        this.claimInfo.bankAccountVo.subBankCode = undefined;
        this.claimInfo.bankAccountVo.subBankName = undefined;
      } else {
        this.accountNameIsReadonly = false;
        this.accountCardNoIsReadonly = false;

        var beneficiaryInfo = this.beneficiaryInfoMap.get(value);
        if (undefined !== beneficiaryInfo) {
          this.claimInfo.bankAccountVo.accountName = beneficiaryInfo.bankAccountName;
          this.claimInfo.bankAccountVo.accountCardNo = beneficiaryInfo.cardNo;
          this.claimInfo.bankAccountVo.accountNo = beneficiaryInfo.bankCardNo;

          if (undefined !==  beneficiaryInfo.bankCode
              && undefined !==  beneficiaryInfo.bankName) {
            if (this.bankOtherCode === beneficiaryInfo.bankCode) {
              this.bankColumns.unshift({"text": beneficiaryInfo.bankName, "value": beneficiaryInfo.bankCode});
            }
            this.claimInfo.bankAccountVo.bankCode = beneficiaryInfo.bankCode;
            this.claimInfo.bankAccountVo.bankName = beneficiaryInfo.bankName;
          }

          if (2 == beneficiaryInfo.cardType) {
            this.claimInfo.bankAccountVo.cardType = 2;
          } else {
            this.claimInfo.bankAccountVo.cardType = 3;
          }

          this.claimInfo.bankAccountVo.bankProvinceCode = beneficiaryInfo.bankProvinceCode;
          this.claimInfo.bankAccountVo.bankProvinceName = beneficiaryInfo.bankProvinceName;
          this.selectBankProvince(this.claimInfo.bankAccountVo.bankProvinceCode);
          this.claimInfo.bankAccountVo.bankCityCode = beneficiaryInfo.bankCityCode;
          this.claimInfo.bankAccountVo.bankCityName = beneficiaryInfo.bankCityName;
          this.loadSubBankList();
          this.claimInfo.bankAccountVo.subBankCode = beneficiaryInfo.subBankName;
          this.claimInfo.bankAccountVo.subBankName = beneficiaryInfo.subBankName;


          if (undefined !== beneficiaryInfo.cardUrl && beneficiaryInfo.cardUrl.length > 1) {
            for (var idx in this.materialTypeList) {
              var type = this.materialTypeList[idx];
              if (1 === type.materialType) {
                type.fileList.push({url: beneficiaryInfo.cardUrl, materialType: 1});
              }
            }
          }
        }


      }

    },

    searchBankValue(value) {
      //eslint-disable-next-line no-debugger
      // debugger
      if (this.tianAnCmpCode !== this.policyInfo.companyCode && !this.claimInfoTypes.includes(1)) {
        // if (this.bankColumns.length > this.bankColumnsCount) {
        //   this.bankColumns.splice(0, 1);
        // }
        if (this.bankOtherCode === this.bankColumns[0].value) {
          this.bankColumns.splice(0, 1);
        }
        this.bankColumns.unshift({"text": value, "value": this.bankOtherCode});
      } else  {
        //输入银行名称
        if (this.claimInfoTypes.includes(23)) {
          if (this.bankOtherCode === this.bankColumns[0].value) {
            this.bankColumns.splice(0, 1);
          }
          this.bankColumns.unshift({"text": value, "value": this.bankOtherCode});
        }
      }
    },
    selectBank(value) {
      for (var idx in this.bankColumns) {
        var bankInfo = this.bankColumns[idx];
        if (value === bankInfo.value) {
          this.claimInfo.bankAccountVo.bankName = bankInfo.text;
        }
      }

      this.showBankAddress = true
      this.showSubBank = true
      if (value === 'ALIPAY') {
        this.showBankAddress = false
        this.showSubBank = false
      }

      if (this.claimInfoTypes.includes(11)) {
        this.showBankAddress = false
        this.showSubBank = false
      }

      if (this.claimInfoTypes.includes(15)) {
        var productClaim =  this.productClaimMap[15];
        if (productClaim && productClaim.remark) {
          var remark = JSON.parse(productClaim.remark);
          if (remark && remark.includes(value)) {
            this.showBankAddress = false
            this.showSubBank = false
          }
        }
      }

      this.claimInfo.bankAccountVo.subBankCode = undefined;
      if (this.tianAnCmpCode === this.policyInfo.companyCode) {
        this.getTianAnSubBank()
      } else {
        this.loadSubBankList();
      }
    },
    filterOptionBank(input, option) {
      console.log(input, option);
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    selectBankProvince(value) {
      for (var idx in this.bankProvinceList) {
        var areaInfo = this.bankProvinceList[idx];
        if (value === areaInfo.value) {
          this.claimInfo.bankAccountVo.bankProvinceName = areaInfo.text;
        }
      }
      if (this.tianAnCmpCode === this.policyInfo.companyCode) {
        this.getTianAnCity(value);
      } else {
        this.bankCityList = this.bankProvinceCityMap.get(value);
      }

      this.claimInfo.bankAccountVo.bankCityCode = undefined;
      this.claimInfo.bankAccountVo.subBankCode = undefined;
      // this.loadSubBankList();
    },
    selectBankCity(value) {
      for (var idx in this.bankCityList) {
        var areaInfo = this.bankCityList[idx];
        if (value === areaInfo.value) {
          this.claimInfo.bankAccountVo.bankCityName = areaInfo.text;
        }
      }
      this.claimInfo.bankAccountVo.subBankCode = undefined;
      if (this.tianAnCmpCode === this.policyInfo.companyCode) {
        this.getTianAnSubBank()
      } else {
        this.loadSubBankList();
      }
    },

    searchSubBankValue(value) {

      if (this.tianAnCmpCode !== this.policyInfo.companyCode) {
        if (this.subBankColumns.length > this.subBankColumnsCount) {
          this.subBankColumns.splice(0, 1);
        }
        this.subBankColumns.unshift({"text": value, "value": value});
      }
    },
    selectSubBank(value) {
      for (var idx in this.subBankColumns) {
        var subBank = this.subBankColumns[idx];
        if (value == subBank.value) {
          this.claimInfo.bankAccountVo.subBankName = subBank.text;
        }
      }
    },

    loadSubBankList() {
      this.subBankColumns = [];
      if (undefined === this.claimInfo.bankAccountVo.bankCode
          || undefined === this.claimInfo.bankAccountVo.bankProvinceCode
          || undefined === this.claimInfo.bankAccountVo.bankCityCode) {
        return;
      }

      getSubBankList({
        bankCode: this.claimInfo.bankAccountVo.bankCode,
        provinceCode: this.claimInfo.bankAccountVo.bankProvinceCode,
        cityCode: this.claimInfo.bankAccountVo.bankCityCode
      }).then(res => {
        if (this.successCode === res.code) {
          var subBankList = res.data;
          var subBanks = [];
          for (var idx in subBankList) {
            var subBank = subBankList[idx];
            subBanks.push({
              "text": subBank.subBankName,
              "value": subBank.subBankCode
            });
          }
          this.subBankColumns = subBanks;
          this.subBankColumnsCount = this.subBankColumns.length;
        }
      }).catch();
    },
    changeReadClaimProtocol() {
      this.isReadClaimProtocol = !this.isReadClaimProtocol;
    },


    uploaderAfterRead(file, detail) {
      var materialTypeIdx;
      for (var idx in this.materialTypeList) {
        var type = this.materialTypeList[idx];
        var typeTemp = this.materialTypeTempList[idx];
        if (type.fileList.length !== typeTemp.fileList.length) {
          materialTypeIdx = idx;
          typeTemp.fileList = this.deepClone(type.fileList);
        }
      }
      file.status = 'uploading';
      file.message = '上传中...';
      uploadImage({file: file.content}).then(res => {

        file.status = 'done';
        if (this.successCode === res.code) {
          file.status = 'done';
          this.materialTypeList[materialTypeIdx].fileList[detail.index].url = res.data;
          this.materialTypeList[materialTypeIdx].fileList[detail.index].materialType = this.materialTypeList[materialTypeIdx].materialType;
        }
      }).catch(() => {
        file.status = 'failed';
        file.message = '上传失败';
      });
    },
    deleteImg() {
      for (var idx in this.materialTypeList) {
        var type = this.materialTypeList[idx];
        var typeTemp = this.materialTypeTempList[idx];
        if (type.fileList.length !== typeTemp.fileList.length) {
          typeTemp.fileList = this.deepClone(type.fileList);
        }
      }
    },

    beforeRead(file) {
      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        new Compressor(file, {
          quality: 0.6,
          success: resolve,
          error(err) {
            console.log(err.message);
          },
        });
      });
    },

    deepClone(source) {
      if (!source && typeof source !== 'object') {
        throw new Error('error arguments', 'deepClone')
      }
      const targetObj = source.constructor === Array ? [] : {}
      Object.keys(source).forEach(keys => {
        if (source[keys] && typeof source[keys] === 'object') {
          targetObj[keys] = this.deepClone(source[keys])
        } else {
          targetObj[keys] = source[keys]
        }
      })
      return targetObj
    },

    /**
     * 处理理赔类型
     */
    dealWithClaimDriveType() {
      //理赔类型
      if (this.claimInfoTypes.includes(13)) {
        if (this.productClaimMap) {
          var productClaim =  this.productClaimMap[13];
          if (productClaim && productClaim.remark) {
            var remark = JSON.parse(productClaim.remark);
            this.claimDriveTypeColumns = remark;
          }
        }
      }
    },

    /**
     * 处理理赔科目
     */
    dealWithClaimSubject() {
      //理赔科目
      if (this.claimInfoTypes.includes(14)) {
        if (this.productClaimMap) {
          var productClaim =  this.productClaimMap[14];
          if (productClaim && productClaim.remark) {
            var remark = JSON.parse(productClaim.remark);
            if (!remark) {
              return;
            }

            var planValue = remark[this.policyInfo.planCode];
            if (null != planValue && planValue.length > 0) {
              this.claimSubjectColumns = planValue;
              return;
            }

            if (remark.type == 1 && remark.value) {
              this.claimSubjectColumns = remark.value;
            } else if (remark.type == 2 && remark.value && this.policyInfo.insuredTimePoint) {
              var point = this.policyInfo.insuredTimePoint?this.policyInfo.insuredTimePoint + '':'1';
              var v =  remark.value[point];
              if (v && v.length > 0) {
                this.claimSubjectColumns = v;
              }
            }
          }
        }
      }
    },

    handleReset () {
      this.$refs.esign.reset();
    },
    handleGenerate () {
      this.$refs.esign.generate().then(res => {
        this.resultImg = res
        console.log(""+ res)
      }).catch(err => {
        alert(err) // 画布没有签字时会执行这里 'Not Signned'
      })
    }

  }

}
</script>

<style lang="less" scoped>
.card {
  margin-bottom: 24px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>

